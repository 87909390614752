// import { faSearch } from '@fortawesome/free-solid-svg-icons'

export default {
  HelpListArticles: [],
  HelpCategories: [],
  configKomercia: {},
  configHelpDesk: {
    url: 'https://helpdesk-node-api-production.up.railway.app',
  },
  configAxios: {
    headers: {
      'content-type': 'application/json',
      Authorization: '',
      'Access-Control-Allow-Origin': '*',
      Access: 'application/json',
    },
  },
  categoryName: '',
  showLateral: false,
  articleByCategory: [],
  idStore: '',
  pathName: '',
  authorName: [],
  settingByHelpDesk: '',
  showVideo: false,
}
